import React, { useContext } from 'react';
import Icon from '@ant-design/icons';
import { DictationSessionContext } from '../../context/DictationSessionContextProvider.jsx';
import { AudioContextContext } from '../../context/AudioContextContextProvider.jsx';

const ReplaySvg = () => (
  <svg 
    viewBox="0 0 1024 1024"
    style={{ width: '1em', height: '1em', fill: 'currentColor', verticalAlign: 'middle' }}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M512 1024C230.4 1024 0 793.6 0 512 0 230.4 230.4 0 512 0c281.6 0 512 230.4 512 512C1024 793.6 793.6 1024 512 1024zM736 384 736 256l-64 0 0 57.6c-99.2-80-246.4-76.8-342.4 16-99.2 99.2-99.2 262.4 0 361.6 99.2 99.2 262.4 99.2 361.6 0l-3.2-3.2c9.6-9.6 12.8-19.2 12.8-35.2 0-25.6-22.4-48-48-48-19.2 0-35.2 9.6-41.6 25.6-64 51.2-156.8 48-214.4-9.6-64-64-64-163.2 0-227.2 57.6-57.6 144-60.8 208-16l-64 0 0 64 128 0 64 0L732.8 384z" fill="#8350f2"></path>
  </svg>
);

const ReplayIcon = (props) => <Icon component={ReplaySvg} {...props} />;

const ReplayButton = () => {

  const { dictationSession, currentDictationIndex } = useContext(DictationSessionContext);
  const { audioRef, isPlaying, setIsPlaying } = useContext(AudioContextContext);

  const handleReplay = () => {
    const t_start = dictationSession['sentences'][currentDictationIndex]['start'] / 1000;
    const audio = audioRef.current;
    audio.currentTime = t_start;
    audio.play();
    setIsPlaying(true);
  };

  return (
    <ReplayIcon style={{ fontSize: '28px' }} onClick={handleReplay}/>
  );
}

export default ReplayButton;