import React, { useContext, useEffect } from "react";
import { PauseCircleFilled, PlayCircleFilled } from "@ant-design/icons";

import { DictationSessionContext } from "../../context/DictationSessionContextProvider.jsx";
import { AudioContextContext } from '../../context/AudioContextContextProvider.jsx';

const AudioPlayer = () => {

  const { dictationSession } = useContext(DictationSessionContext);
  const { audioRef, isPlaying, setIsPlaying } = useContext(AudioContextContext);

  const handlePlay = () => {
    if (!isPlaying) {
      audioRef.current.play();
      setIsPlaying(true);
    }
  }

  const handlePause = () => {
    audioRef.current.pause();
    setIsPlaying(false);
  }

  const ButtonStyle = {
    fontSize: '28px', 
    color: "#8350f2", 
    paddingLeft: "20px",
    paddingRight: "20px",
  }

  return (
    <div 
      style={{ 
        width: "100%", 
        display: 'flex',
        justifyContent: 'center'
      }}
    >
      {
        isPlaying 
        ? <PauseCircleFilled style={ButtonStyle} onClick={handlePause}/>
        : <PlayCircleFilled style={ButtonStyle} onClick={handlePlay}/>
      }
    </div>
  );
};

export default AudioPlayer;