import React, { useState, useRef, useMemo } from 'react';
import { FFmpeg } from '@ffmpeg/ffmpeg';
import { toBlobURL } from '@ffmpeg/util';

export const FFMpegContext = React.createContext();

const FFMpegContextProvider = ({ children }) => {

  /*
    FFmpeg.wasm:
    https://github.com/ffmpegwasm/ffmpeg.wasm
  */
  const [isFFMpegReady, setIsFFMpgeReady] = useState(false);
  const ffmpegRef = useRef(new FFmpeg());

  const initFFmpeg = async () => {
    if (isFFMpegReady) {
      return;
    }
    const baseURL = 'https://unpkg.com/@ffmpeg/core@0.12.6/dist/umd'
    const ffmpeg = ffmpegRef.current;
    await ffmpeg.load({
      coreURL: await toBlobURL(`${baseURL}/ffmpeg-core.js`, 'text/javascript'),
      wasmURL: await toBlobURL(`${baseURL}/ffmpeg-core.wasm`, 'application/wasm'),
    })
    setIsFFMpgeReady(true);
  }

  const FFMpegContextValue = useMemo(() => ({
    isFFMpegReady,
    ffmpegRef,
    initFFmpeg,
  }), [isFFMpegReady, ffmpegRef]);

  return (
    <FFMpegContext.Provider value={FFMpegContextValue}>
      {children}
    </FFMpegContext.Provider>
  );
}

export default FFMpegContextProvider;