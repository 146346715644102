import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { message, Space, Select, Flex, Pagination, Modal, Dropdown, Spin } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import AlphabeticLogo from "../logo/AlphabeticLogo.jsx";
import API from '../../api/api.js';
import FileUploader from '../transcription/FileUploader.jsx';
import { DictationSessionContext } from '../../context/DictationSessionContextProvider.jsx';
import { lengthToHHMMSS, formatTimeAgo } from '../../util/FormatUtil.jsx';

import './MyLibrary.css';

const MyLibrary = ({ setActiveTab }) => {

  const navigate = useNavigate();   // 路由助手
  const { t, i18n } = useTranslation();
  const { startDictationSession, getDictationHistory } = useContext(DictationSessionContext);

  /**
   * 获取用户音频列表相关 state 与 handler
   */
  /**
   *  "audio-list": [
        {
          "audioUuid": "abbbca15-b4c1-4feb-9a13-f7137171b5a9",
          "userId": 10000002,
          "title": "TPO_060_L001_copy",
          "audioFileUrl": "audio/abbbca15-b4c1-4feb-9a13-f7137171b5a9.mp3",
          "mimeType": "audio/mpeg",
          "size": 5106298,
          "length": 0,
          "transcriptFileUrl": "",
          "totalSegNum": 0,
          "uploadTime": "2024-10-25T08:24:23",
          "transcribeTime": null,
          "transcribeTimeSpent": 0,
          "coverImg": "",
          "lastExerciseSeg": 0,
          "lastExerciseTime": null
        },
      ]
   */

  const AUDIO_PAGE_SIZE = 6;
  const [audioList, setAudioList] = useState([]);
  const [isAudioListLoading, setIsAudioListLoading] = useState(false); 
  const [sortBy, setSortBy] = useState('latestPlayed'); // sortBy: ['latestPlayed', 'latestTranscribed']
  const [currentPage, setCurrentPage] = useState(1);
  const [audioTotalNum, setAudioTotalNum] = useState(0);

  const getAudioList = async (page, pageSize) => {
    setIsAudioListLoading(true);
    try {
      const response = await API
        .get("/audio/list-library", {
          params: {
            'page': page,
            'page-size': pageSize,
            'sort-by': sortBy
          }
        })
      setAudioTotalNum(response.data['total-num']);
      setAudioList(response.data['audio-list']);
      setIsAudioListLoading(false);
    } catch (error) {
      if (!error.response) {
        message.error("Network error. Please check your connection.");
      } else {
        message.error(error.response.data['error']);
      }
      setIsAudioListLoading(false);
    }
  }

  useEffect(() => {
    getAudioList(currentPage, AUDIO_PAGE_SIZE);
  }, [currentPage, sortBy])

  const handleSortByChange = (value) => {
    setSortBy(value);
  }

  /*
  * 删除音频相关 handler
  */
  const [isDeleteAudioModalOpen, setIsDeleteAudioModalOpen] = useState(false);
  const [deleteAudioUuid, setDeleteAudioUuid] = useState('');

  const deleteAudio = () => {
    API
      .delete("audio/" + deleteAudioUuid)
      .then((response) => {
        message.success(t("音频已删除！"));
        setIsDeleteAudioModalOpen(false);
        getAudioList(currentPage, AUDIO_PAGE_SIZE);
      })
      .catch((error) => {
        if (!error.response) {
          message.error(error);
        } else {
          message.error(error.response.data["error"]);
        }
        setIsDeleteAudioModalOpen(false);
      });
  }

  /**
   * 转录音频相关 handler
   */
  const [isTranscriptionModalOpen, setIsTranscriptionModalOpen] = useState(false);
  const [transcriptionAudioUuid, setTranscriptionAudioUuid] = useState('');
  const [transcriptionCost, setTranscriptionCost] = useState(0);
  
  const submitTranscriptionTask = () => {
    API
      .post(
        "/task/",
        null, 
        {
          params: {
            "audio-id": transcriptionAudioUuid
          }
        }
      )
      .then((response) => {
        message.success(t("转录任务已提交！"));
        setIsTranscriptionModalOpen(false);
        setActiveTab('transcription-history-tab'); // 跳转转录任务目录
      })
      .catch((error) => {
        if (!error.response) {
          message.error(error);
        } else {
          message.error(error.response.data["error"]);
        }
        setIsTranscriptionModalOpen(false);
      });
  }

  const calculateTranscriptionCost = (duration) => {
    // duration 单位秒
    // 收费标准：5分钟以内收费 5 点券，5分钟以上收费 N 点券
    const minutesEstimated = Math.ceil(duration / 60);
    const priceEstimated = Math.max(minutesEstimated, 5);
    return priceEstimated;
  }

  /**
   * 音频卡片下拉选单
   */
  const createAudioCardMenu = (audioObject) => {
    if (!audioObject.transcribeTime) {
      return [
        {
          key: audioObject.audioUuid + '-card-transcribe',
          label: <span onClick={() => {
            setTranscriptionCost(calculateTranscriptionCost(audioObject.length));
            setIsTranscriptionModalOpen(true);
            setTranscriptionAudioUuid(audioObject.audioUuid);
          }}>
            {t("转录音频")}
          </span>,
          disabled: audioObject.accessLevel === 2
        },
        {
          key: audioObject.audioUuid + '-card-delete',
          label: 
            <span onClick={() => {
              if (audioObject.accessLevel !== 2) {
                setDeleteAudioUuid(audioObject.audioUuid);
                setIsDeleteAudioModalOpen(true);
              }
            }}>
              {t("删除音频")}
            </span>,
          disabled: audioObject.accessLevel === 2
        }
      ]
    } else {
      return [
        {
          key: audioObject.audioUuid + '-card-play',
          label: 
            <span onClick={() => {
              startDictationSession(audioObject.audioUuid);
              navigate('/play/'+audioObject.audioUuid);
            }}>
              {t("去听写")}
            </span>
        },
        {
          key: audioObject.audioUuid + '-view-history',
          label: 
            <span onClick={() => {
              getDictationHistory(audioObject.audioUuid);
              navigate('/history-play/'+audioObject.audioUuid)
            }}>
              {t("查看练习记录")}
            </span>
        },
        {
          key: audioObject.audioUuid + '-card-delete',
          label: 
            <span onClick={() => {
              if (audioObject.accessLevel !== 2) {
                setDeleteAudioUuid(audioObject.audioUuid);
                setIsDeleteAudioModalOpen(true);
              }
            }}>
              {t("删除音频")}
            </span>,
          disabled: audioObject.accessLevel === 2
        }
      ]
    }
  }

  const buildPracticeProgressHint = (audioObject) => {
    if (!audioObject.transcribeTime) {
      return "--/--";
    } else {
      return (audioObject.lastExerciseTime ? audioObject.lastExerciseSeg : "0") + "/" + audioObject.totalSegNum
    }
  }

  const buildRecentPlayHint = (audioObject) => {
    if (i18n.language === 'zh') {
      return audioObject.lastExerciseTime 
        ? formatTimeAgo(audioObject.lastExerciseTime) + " 听写" 
        : formatTimeAgo(audioObject.uploadTime) + " 上传"
    }
    if (i18n.language === 'en') {
      return audioObject.lastExerciseTime 
        ? "Listened " + formatTimeAgo(audioObject.lastExerciseTime) 
        : "Uploaded " + formatTimeAgo(audioObject.uploadTime)
    }
  }

  return (
    <>
      <Space
        direction="vertical"
        size="large"
        style={{
          display: 'flex',
        }}
      >
        {
        isAudioListLoading
        ? <Flex justify="center" align="center">
            <Spin />
          </Flex>
        : <div>
            {audioList.map((audioObject, index) => (
              <div className='audio-card' key={'audio-card-'+audioObject.audioUuid}>
                <AlphabeticLogo title={audioObject.title} width={36} height={36}/>
                <div className='audio-text-info'>
                  <div className='audio-text-info__title'>{audioObject.title}</div>
                  <div className='audio-text-info__detail'>
                    <span className='audio-text-info__detail-text'>{lengthToHHMMSS(audioObject.length)}</span>
                    <span className='audio-text-info__detail-text'>{buildPracticeProgressHint(audioObject)}</span>
                    <span className='audio-text-info__detail-text'>
                      {buildRecentPlayHint(audioObject)}
                    </span>
                  </div>
                </div>
                <Dropdown
                  className='audio-card-dropdown' 
                  menu={{ items: createAudioCardMenu(audioObject), }}
                  trigger={['click']}
                >
                  <EllipsisOutlined 
                    style={{ fontSize: '16px', color: 'rgba(0, 0, 0, 0.25)'}}
                  />
                </Dropdown>
              </div>
            ))}
          </div>
        }
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Select
            defaultValue="latestPlayed"
            style={{ width: 120 }}
            onChange={handleSortByChange}
            options={[
              { value: 'latestPlayed', label: t("最近播放") },
              { value: 'latestTranscribed', label: t("最近转录") },
            ]}
          />
          <Pagination 
            defaultCurrent={currentPage} 
            pageSize={AUDIO_PAGE_SIZE} 
            total={audioTotalNum} 
            onChange={(page) => {
              setCurrentPage(page);
            }}
          />
        </div>
        <FileUploader />
      </Space>
      <Modal 
        open={isDeleteAudioModalOpen}
        onOk={deleteAudio}
        onCancel={() => {
          setDeleteAudioUuid('');
          setIsDeleteAudioModalOpen(false);
        }}>
        <p>{t("您确定要删除这个音频吗？")}</p>
      </Modal>
      <Modal
        open={isTranscriptionModalOpen}
        onOk={submitTranscriptionTask}
        onCancel={() => {
          setTranscriptionAudioUuid('');
          setIsTranscriptionModalOpen(false);
          setTranscriptionCost(0);
        }}
      >
        <p>{t("您确定识别该音频的文本内容吗？预计花费") + " " + transcriptionCost + " " + t("点券")}</p>
      </Modal>
    </>
  );
}

export default MyLibrary;