import React, { useContext, useEffect, useState, useRef, useMemo } from "react";
import { DictationSessionContext } from "./DictationSessionContextProvider.jsx";

export const AudioContextContext = React.createContext();

const AudioContextContextProvider = ({ children }) => {

  const { dictationSession, currentDictationIndex } = useContext(DictationSessionContext);
  const audioRef = useRef(null);
  const [ isPlaying, setIsPlaying ] = useState(false);

  useEffect(() => {
    if (dictationSession) {
      const audio = audioRef.current;
      setIsPlaying(false);
      audioRef.current.pause();
  
      const t_start = dictationSession['sentences'][currentDictationIndex]['start'] / 1000;
      const t_end = dictationSession['sentences'][currentDictationIndex]['end'] / 1000;
      
      audio.currentTime = t_start;
      const handleTimeUpdate = () => {
        if (audio.currentTime >= t_end) {
          audio.pause();
          setIsPlaying(false);
          audio.currentTime = t_start;
        }
      };
  
      audio.addEventListener('timeupdate', handleTimeUpdate);
      return () => {
        audio.removeEventListener('timeupdate', handleTimeUpdate);
      };
    }
  }, [dictationSession, currentDictationIndex]);

  const audioContextContextValue = useMemo(() => ({
    audioRef,
    isPlaying,
    setIsPlaying
  }), [audioRef, isPlaying]);

  return (
    <AudioContextContext.Provider value={audioContextContextValue}>
      {dictationSession && <audio ref={audioRef} src={dictationSession["audio-src"]}/>}
      {children}
    </AudioContextContext.Provider>
  )
}

export default AudioContextContextProvider;