import React, { useContext } from "react";
import { Typography, Flex } from "antd";
import AudioPlayer from '../dictation/AudioPlayer.jsx';
import ReplayButton from '../dictation/ReplayButton.jsx';
import AlphabeticLogo from '../logo/AlphabeticLogo.jsx';
import ProgressBar from './ProgressBar.jsx';
import { lengthToHHMMSS } from '../../util/FormatUtil.jsx';
import { DictationSessionContext } from "../../context/DictationSessionContextProvider.jsx";

import "./AudioInfoPanel.css";

const AudioInfoPanelHorizontal = () => {

  /**
   * 当前正在播放的 Session 信息
   * {
      "session-id": 2,
      "session-create-time": "2024-10-27T06:32:21.360163438"
      "audio-uuid": "c2cff0e3-318c-4c5e-bd4f-e16ab619260e",
      "audio-src": "http://echo-us-west.oss-us-west-1.aliyuncs.com/audio/c2cff0e3-318c-4c5e-bd4f-e16ab619260e.mp3?Expires=1730003541&OSSAccessKeyId=LTAI5tS66b6yK44Hm7Z6HS7q&Signature=DNYmMEWd1betrZarfVy7cjRZJhs%3D",
      "title": "Gettysburg Address",
      "duration": 192,
      "mimeType": "audio/mpeg",
      "access-level": 2,
      "segments-num": 19,
      "cover-img": "https://xxx",
      "last-dictation-pos": 0,
      "sentences": [
        {
          "sentenceId": 92,
          "audioUuid": "c2cff0e3-318c-4c5e-bd4f-e16ab619260e",
          "index": 1,
          "text": "Now listen to two students discussing the letter. ",
          "start": 10, // 单位: ms
          "end": 3760, // 单位: ms
        },
      ],
    }
   */
  const {dictationSession, currentDictationIndex, setCurrentDictationIndex} = useContext(DictationSessionContext);

  if (!dictationSession) {
    // No dictation session yet
    return (
      <div className="audio-info-panel-horizontal">
        <img src="/img/audio-default.png" alt="default-logo" style={{ width: "36px", height: "36px", borderRadius: "12px"}}/>
        <div className="audio-info-text-container">
          <div className="audio-title" style={{ color: "rgba(0, 0, 0, 0.25)" }}>--</div>
          <div className="audio-detail-info">
            <Typography.Text type="secondary" style={{ fontSize: '12px' }}>{lengthToHHMMSS(0)}</Typography.Text>
            <Typography.Text type="secondary" style={{ fontSize: '12px' }}>-- / --</Typography.Text>
            <ProgressBar completed={0} total={1}/>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="audio-info-panel-horizontal">
        <AlphabeticLogo title={dictationSession.title} width={36} height={36}/>
        <div className="audio-info-text-container">
          <Typography.Text strong>{dictationSession.title}</Typography.Text>
          <div className="audio-detail-info">
            <Typography.Text type="secondary" style={{ fontSize: '12px' }}>{lengthToHHMMSS(dictationSession.duration)}</Typography.Text>
            <Typography.Text type="secondary" style={{ fontSize: '12px' }}>{(currentDictationIndex + 1)+" / "+dictationSession["segments-num"]}</Typography.Text>
            <ProgressBar completed={currentDictationIndex} total={dictationSession["segments-num"]} setCompleted={setCurrentDictationIndex}/>
          </div>
        </div>
        <div>
          <Flex>
            <AudioPlayer />
            <ReplayButton />
          </Flex>
        </div>
      </div>
    );
  }
}

export default AudioInfoPanelHorizontal;
