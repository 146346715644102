import React, { useState, useEffect } from 'react';
import { Input, Typography } from 'antd';
import { diffWords } from 'diff';

// import "./DictationInput.css";

export const DictationDisplay = ({ groundTruthStr, inputStr }) => {

  /**
   * Diffwords output:
   * [
   *    { count: 1, added: false, removed: true, value: 'This' }, // remove from the old String
        { count: 1, added: true, removed: false, value: 'That' }, // add to the new String
        { count: 2, added: false, removed: false, value: ' Is the ' }, // it is the same in both old and new String
        // ...
   * ]
   */
  const [diffWordsOutput, setDiffWordsOutput] = useState([]);

  useEffect(() => {
    setDiffWordsOutput(diffWords(inputStr, groundTruthStr, { ignoreCase: true }));
  }, [ groundTruthStr, inputStr ]);

  return (
    <div>
    {diffWordsOutput.map(( output, index ) => {
      if (output.added) {
        return (
          <Typography.Text 
            key={index+'-'+output.value} 
            style={{ color: '#8350f2', fontSize: '18px' }} 
            strong
          >
            {output.value}
          </Typography.Text>
        );
      } else if (output.removed) {
        return (
          <Typography.Text 
            key={index+'-'+output.value} 
            style={{ textDecoration: 'line-through', fontSize: '18px'}}
          >
            {output.value}
          </Typography.Text>
        );
      } else {
        return (
          <Typography.Text
            key={index+'-'+output.value} 
            style={{ fontSize: '18px' }}
          >
            {output.value}
          </Typography.Text>
        );
      }
    })}
    </div>
  );
}

export const DictationInput = ({ setDictationText }) => {

  const handleChange = (e) => {
    setDictationText(e.target.value);
  };

  return (
    <Input.TextArea
      variant="borderless"
      autoSize
      placeholder="Write down dictation here..."
      onChange={handleChange}
      style={{
        padding: 0,
        backgroundColor: 'transparent',
        fontFamily: 'inherit',
        fontSize: '18px',
        lineHeight: 'inherit',
        color: 'inherit',
      }}
    >
    </Input.TextArea>
  );
}
