import React, { useState, useEffect, useMemo } from 'react';
import { message } from 'antd';
import API from '../api/api.js';

export const AuthContext = React.createContext();

const AuthContextProvider = ({ children }) => {

  /*
    Authentication: isLoggedIn + JWT token + user information
  */
  const [authHolder, setAuthHolder] = useState({
    isLoggedIn: false,
    token: null,
    user: {
      nickname: "游客",
      username: null,
      email: null,
      balance: null,
      profileImg: null,
      registerTime: null,
    }
  });

  // 初始化登陆状态，Cookie 未过期情况下尝试自动登录
  useEffect(() => {
    API
    .get('/return')
    .then(response => {
      // 登录成功 将用户信息存入 AuthContext 关闭 Modal
      setAuthHolder((prevState) => ({
        ...prevState,
        isLoggedIn: true, 
        user: {
          username: response.data["username"],
          nickname: response.data["nickname"],
          email: response.data["email"],
          balance: response.data["balance"],
          profileImg: response.data["profile-img"],
          registerTime: response.data["register-time"]
        },
      }));
    })
    .catch(error => {
      // 用户 cookie 已失效
      // TODO: 后端查看到失效的cookie后将取消cookie
    })
  }, []);

  // 使用 useMemo 导出登录认证相关状态，即便其他上层 Context 重新渲染导致 AuthContext 重新渲染，可以直接使用缓存过后的 authHolder
  const authContextValue = useMemo(() => ({
    authHolder,
    setAuthHolder,
  }), [authHolder]);

  return (
    <AuthContext.Provider value={authContextValue}>
      {children}
    </AuthContext.Provider>
  );
}

export default AuthContextProvider;