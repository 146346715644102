import React, { useState, useMemo } from 'react';

export const SettingContext = React.createContext();

const SettingContextProvider = ({ children }) => {

  /**
   * Settings Context: 
   * {
   *   replay-round: 2 ( default 2, allow 1 - 5)
   *   do-check-answer: true (播放页面完成听写后是否展示答案，默认 true)
   * }
   */
  const [settingHolder, setSettingHolder] = useState({
    'replay-round': 2,
    'do-check-answer': true,
  })

  // TODO: 在后端持久化用户设置状态

  const settingContextValue = useMemo(() => ({
    settingHolder,
    setSettingHolder,
  }), [settingHolder])

  return (
    <SettingContext.Provider value={settingContextValue}>
      {children}
    </SettingContext.Provider>
  );
}

export default SettingContextProvider;