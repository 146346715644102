import React, { useEffect, useRef, useContext } from 'react';
import { BrowserRouter as Router, Route, Routes, Outlet, Navigate } from 'react-router-dom';
import { Layout, Row, Col, Splitter } from 'antd';

import ErrorBoundary from './error/ErrorBoundary.jsx';
import AuthContextProvider from './context/AuthContextProvider.jsx';
import SettingContextProvider from './context/SettingContextProvider.jsx';
import FFMpegContextProvider from './context/FFMpegContextProvider.jsx';
import AudioContextContextProvider from './context/AudioContextContextProvider.jsx';
import DictationContextProvider from './context/DictationSessionContextProvider.jsx';
import AntdConfigProvider from './context/AntdConfigProvider.jsx';
import { AuthContext } from './context/AuthContextProvider.jsx';

import TranslateButton from './components/header/TranslateButton.jsx'; 
import AvatarDropdown from './components/header/AvatarDropdown.jsx';
import AuthLayout from './components/authentication/AuthLayout.jsx';
import LogIn from './components/authentication/LogIn.jsx';
import Register from './components/authentication/Register.jsx';
import ControlConsole from './components/ControlConsole.jsx';
import Dictation from './components/dictation/Dictation.jsx';
import DictationHistory from './components/dictation/DictationHistory.jsx';
import InvalidUrlPage from './components/error/InvalidUrlPage.jsx';
import AudioInfoPanelHorizontal from './components/panel/AudioInfoPanelHorizontal.jsx';

import "./App.css";

const { Header, Content, Footer, Sider } = Layout; 

/**
 * App入口，使用 Router 包裹在最外层，使各层ContextProvider可以使用URL信息进行全局状态的修改
 */
export default function App() {
  return (
    <Router>
      <AppWithContext />
    </Router>
  );
}

/**
 * 将全局状态 Provider 提供给 App
 */
const AppWithContext = () => {
  return (
    <AuthContextProvider>
      <ErrorBoundary>
        <AntdConfigProvider>
          <FFMpegContextProvider>
            <SettingContextProvider>
              <DictationContextProvider>
                <AudioContextContextProvider>
                  <AppRoutes />
                </AudioContextContextProvider>
              </DictationContextProvider>
            </SettingContextProvider>
          </FFMpegContextProvider>
        </AntdConfigProvider>
      </ErrorBoundary>
    </AuthContextProvider>
  );
}

/**
 * 定义 App 当中不同路径对应的页面
 */
const AppRoutes = () => {

  // 受保护的页面，未登录状态下自动重定向到登录页
  const { authHolder, setAuthHolder } = useContext(AuthContext);
  const ProtectedRoute = () => {
    return authHolder.isLoggedIn ? <Outlet /> : <Navigate to="/log-in" />;
  }

  return (
    <Routes>
      <Route 
        element={
          <MainLayout />
        }
      >
        {/* 游客可访问的页面 */}
        <Route 
          path='/' 
          element={
            authHolder.isLoggedIn 
            ? <Navigate to="/play" />
            : <Dictation />
          }
        >
        </Route>

        {/* 游客无权限访问的页面，自动路由到登录页 */}
        <Route element={<ProtectedRoute />}>
          <Route 
            path='/play' 
            element={
              <Dictation />
            }
          >
          </Route>
          <Route 
            path='/play/:audioUuid' 
            element={
              <Dictation />
            }
          >
          </Route>
          <Route path='/history-play/:audioUuid' element={<DictationHistory />}></Route>
        </Route>
      </Route>

      <Route element={<AuthLayout />}>
        <Route path="/log-in" element={<LogIn />} />
        <Route path="/register" element={<Register />} />
      </Route>

      {/* 统一处理所有无效路径 */}
      <Route path="/error" element={<InvalidUrlPage />} />
      <Route path="*" element={<InvalidUrlPage />} />
    </Routes>
  );
}

/**
 * App 主页面 Layout
 */
const MainLayout = () => {
  return (
    <Layout style={{ minHeight: '100vh', backgroundColor: '#ffffff', }} >
      <Header style={{ padding: 0, background: "#ffffff", borderBottom: "1px solid #eeeeee", marginBottom: "24px"}} >
        <Row justify="space-around" align="middle">
          <Col className='header-col' span={1}>
            <img src="/img/logo.png" alt="Logo" style={{width: "40px", height:"40px"}}/>
          </Col>
          <Col className='header-col' span={20}>
            <div>{/* empty */}</div>
          </Col>
          <Col className='header-col' span={1} >
            <div>{/* notification */}</div>
          </Col>
          <Col className='header-col' span={1} >
            <TranslateButton />
          </Col>
          <Col className='header-col' span={1} >
            <AvatarDropdown />
          </Col>
        </Row>
      </Header>
      <Content>
        <Splitter>
          <Splitter.Panel resizable={true}>
            <Outlet />
          </Splitter.Panel>
          <Splitter.Panel defaultSize="300" max="300" collapsible resizable={true}>
            <ControlConsole />
          </Splitter.Panel>
        </Splitter>
      </Content>
      <AudioInfoPanelHorizontal />
    </Layout>
  );
}