import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Checkbox, Form, Input, Flex, Typography, message, Row, Col } from "antd";
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { useTranslation } from "react-i18next";

import API from '../../api/api.js';
import { AuthContext } from '../../context/AuthContextProvider.jsx';

export const logInThroughEmail = (email, password, setAuthHolder) => {
  
  const data = {
    'email': email,
    'password': password
  };

  return API
    .post('/login/email', data, { headers: { 'Content-Type': 'application/json' }})
    .then(response => {
      // 登录成功 将用户信息存入 AuthContext 关闭 Modal
      setAuthHolder((prevState) => ({
        ...prevState,
        isLoggedIn: true, 
        user: {
          username: response.data["username"],
          nickname: response.data["nickname"],
          email: response.data["email"],
          balance: response.data["balance"],
          profileImg: response.data["profile-img"],
          registerTime: response.data["register-time"]
        },
      }));
      message.success("Log in success!");
    })
    .catch(error => {
      if (!error.response) {
        message.error("Network error. Please check your connection.");
      } else {
        message.error(error.response.data['error']);
      }
      return Promise.reject(error);
    });
}

const LogIn = () => {

  const { authHolder, setAuthHolder } = useContext(AuthContext);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const onSubmit = (values) => {
    // 发送登录请求
    logInThroughEmail(
      values.email, values.password, setAuthHolder
    ).then(() => {
      navigate("/play");
    });
  };

  return (
    <Row>
      <Col span={9}></Col>
      <Col span={6}>
        <Form name="login" initialValues={{ remember: false, }} onFinish={onSubmit}>
          <Form.Item name="email" rules={[{ required: true, message: t('请输入您的邮箱！') }, ]}>
            <Input prefix={<UserOutlined />} placeholder="Email" />
          </Form.Item>
          <Form.Item name="password" rules={[{ required: true, message: t('请输入您的密码！'), }, ]}>
            <Input prefix={<LockOutlined />} type="password" placeholder="password"/>
          </Form.Item>
          <Form.Item>
            <Flex justify="space-between" align="center">
              <Form.Item name="remember" valuePropName="checked" noStyle>
                <Checkbox>{t("下次记住我")}</Checkbox>
              </Form.Item>
              {/* <a href="">Forgot password</a> */}
            </Flex>
          </Form.Item>

          <Form.Item>
            <Button block type="primary" htmlType="submit">
              {t("登录")}
            </Button>
            {t("或")} <a onClick={() => {navigate('/register')}}>{t("现在注册！")}</a>
          </Form.Item>
        </Form>
      </Col>
      <Col span={9}></Col>
    </Row>
  );
}

export default LogIn;