import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Tabs, Result, Button } from 'antd';
import { SmileOutlined } from "@ant-design/icons";
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../context/AuthContextProvider.jsx';
import TranscriptionHistoryTab from './transcription/TranscriptionHistoryTab.jsx';
import MyLibrary from './library/MyLibrary.jsx';
import MyHistory from './history/MyHistory.jsx';

import "./ControlConsole.css";

const ControlConsole = () => {

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { authHolder } = useContext(AuthContext);

  /**
   * 管理 Tab 状态
   */
  const [activeTab, setActiveTab] = useState('my-library-tab');
  const tabItems = [
    {
      key: 'my-library-tab',
      label: t("我的音频"),
      children: <MyLibrary setActiveTab={setActiveTab}/>
    },
    {
      key: 'transcription-history-tab',
      label: t("转录任务"),
      children: <TranscriptionHistoryTab activeTab={activeTab}/>,
    },
  ];

  return (
    <div className="control_console_tab">
      { 
        authHolder.isLoggedIn
        ? <Tabs 
            activeKey={activeTab} 
            onTabClick={(key, event) => {setActiveTab(key);}}
            items={tabItems}
          />
        : <Result 
            icon={<SmileOutlined />}
            title={t("登录解锁更多个性化听写功能！")}
            extra={<Button type="primary" onClick={() => {navigate("/log-in")}}>{t("登录")}</Button>}
          />
      }
    </div>
  );
}

export default ControlConsole;