import React, { useState, useRef, useContext } from "react";
import { Button, message, Typography } from "antd";
import { fetchFile } from '@ffmpeg/util';
import { useTranslation } from "react-i18next";

import { FFMpegContext } from "../../context/FFMpegContextProvider.jsx";

import API from '../../api/api.js';
import axios from 'axios';

import './FileUploader.css';

const FileUploader = () => {

  const { t, i18n } = useTranslation();
  const { ffmpegRef, initFFmpeg } = useContext(FFMpegContext);

  /*
    OSS 上传文件相关 State & Handler
  */
  /*
    向服务端后端请求 OSS MetaData
    OSS Upload MetaData: {
      dir: '/audio',
      host: URL,
      policy: String,
      callback: String,
      accessId: String,
      signature: String,
      expiration: Long,
      x:user-id: Long (服务端提供),
      x:audio-uuid: String (服务端提供),
      x:title: String (客户端用户指定),
      x:duration: String（客户端提供）
    }
  */
  const ossMetaData = useRef(null);
  const getOssMetaData = async () => {
    try {
      const response = await API.get("/audio/upload");
      ossMetaData.current = response.data;
    } catch (error) {
      // console.log(error);
    }
  }

  /**
   * 音频文件预处理 State
   */
  const audioExtensionRef = useRef('');
  const audioDuration = useRef(0);
  const [audioFile, setAudioFile] = useState(null);
  const [audioTitle, setAudioTitle] = useState(null);
  const [fileUploadMsg, setFileUploadMsg] = useState(null);
  
  /**
   * 用于解析 FFMpeg Message 日志输出的函数，根据 -i 命令日志输出，确定视频中音轨的格式 
   */
  const regex = /Audio: (\w+)/;
  const parseAudioExtension = (message) => {
    const match = message.match(regex);
    let extension = '';
    if (match) {
      const codec = match[1].toLowerCase();
      if (codec.includes('aac')) {extension = '.aac';}
      else if (codec.includes('mp3')) {extension = '.mp3';}
      audioExtensionRef.current = extension;
    }
  }
  
  /**
   * 根据 -i 命令日志输出，确定音视频长度
   */
  const durationRegex = /Duration:\s(\d+):(\d+):(\d+)[.,](\d+)/;
  const parseDuration = (message) => {
    const durationLine = message.split('\n').find(line => line.includes('Duration:'));
    if (durationLine) {
      const timeMatch = durationLine.match(durationRegex);
      if (timeMatch) {
        const hours = parseFloat(timeMatch[1]);
        const minutes = parseFloat(timeMatch[2]);
        const seconds = parseFloat(timeMatch[3]);
        audioDuration.current = hours * 3600 + minutes * 60 + seconds;
      }
    }
  }

  /**
   * 音频上传预处理 
   * - 如果上传mp4/avi视频文件，那么加载FFMpeg.wasm，检查并提取音轨，赋值给 audioFile
   * - 如果上传mp3/acc音频文件，直接赋值给 audioFile
   * - 校验音频文件大小不超过 20MB
   * - 将文件名赋予 audioTitle
   * @param {*} file 文件对象
   * @returns 
   */
  const beforeUpload = async (file) => {

    if (!file) {
      setAudioFile(null);
      setAudioTitle(null);
      audioExtensionRef.current = '';
      audioDuration.current = 0;
    }

    if (file.type === 'video/mp4' || file.type === 'video/x-msvideo' || file.type === 'audio/aac' || file.type === 'audio/mpeg') {
      // 加载 FFmpeg wasm
      setFileUploadMsg(t("加载中..."));
      await initFFmpeg();
      const ffmpeg = ffmpegRef.current;
      ffmpeg.on('log', ({ message }) => {
        parseAudioExtension(message);
        parseDuration(message);
      })
      setFileUploadMsg(null);

      setFileUploadMsg(t("提取音频..."));
      let srcExtension = null;
      if (file.type === 'video/mp4') { srcExtension = '.mp4'; }
      if (file.type === 'video/x-msvideo') { srcExtension = '.avi'; }
      if (file.type === 'audio/aac') { srcExtension = '.acc'; }
      if (file.type === 'audio/mpeg') { srcExtension = '.mp3'; }
      const srcFileInVirtualOS = 'source' + srcExtension;

      await ffmpeg.writeFile(srcFileInVirtualOS, await fetchFile(file));
      await ffmpeg.exec(['-i', srcFileInVirtualOS]);  
      // -i 指令向 ffmpegMsgRef 输出文件信息，自动调用 parseAudioExtension, parseDuration 方法，解析音轨格式和音频长度

      if (file.type === 'video/mp4' || file.type === 'video/x-msvideo') {
        // 视频需要进一步提取音轨文件
        if (audioExtensionRef.current === '.aac' || audioExtensionRef.current === '.mp3') {
          const dstFileInVirtualOS = 'destination' + audioExtensionRef.current;
          await ffmpeg.exec(['-i', srcFileInVirtualOS, '-map', '0:a,', '-c:a', 'copy', dstFileInVirtualOS]); // 提取音轨
          const data = await ffmpeg.readFile(dstFileInVirtualOS);
  
          let mimeType = null;
          if (audioExtensionRef.current === '.aac') {
            mimeType = 'audio/aac';
          } else if (audioExtensionRef.current === '.mp3') {
            mimeType = 'audio/mpeg';
          }
  
          const extractedAudioFile = new Blob([data.buffer], {
            type: mimeType
          });

          if (extractedAudioFile.size / 1024 / 1024 < 20) {
            setAudioFile(extractedAudioFile);
            setFileUploadMsg(null);
          } else {
            message.error(t("音频文件不能超过 20MB！"));
            return false;
          }
        } else {
          message.error(t("不支持的音频格式！"));
          return false;
        }
      }
      else if (file.type === 'audio/aac' || file.type === 'audio/mpeg') {
        if (file.size / 1024 / 1024 >= 20) {
          message.error(t("音频文件不能超过 20MB！"));
          setFileUploadMsg(null);
          return false;
        }
        audioExtensionRef.current = file.name.slice(file.name.lastIndexOf('.'));
        setAudioFile(file);
        setFileUploadMsg(null);
      } 
    } else {
      message.error(t("支持 .mp4, .avi, .mp3, .aac 文件上传"));
      return false;
    }

    setAudioTitle(file.name.slice(0, file.name.lastIndexOf('.')));
  }

  /**
   * 携带 OSSMetaData 向 Aliyun OSS 上传 audioFile
   */
  const [audioUploading, setAudioUploading] = useState(false);
  const uploadAudio = async () => {
    if (!audioFile) {
      message.error(t("没有文件！"));
      return;
    }

    // 获取最新的 OSS MetaData (包括 audio-uuid)
    await getOssMetaData();

    const filename = ossMetaData.current['x:audio-uuid'] + audioExtensionRef.current;
    const formData = new FormData();
    formData.append('key', ossMetaData.current.dir + filename);
    formData.append('policy', ossMetaData.current.policy);
    formData.append('callback', ossMetaData.current.callback);
    formData.append('OSSAccessKeyId', ossMetaData.current.accessId);
    formData.append('signature', ossMetaData.current.signature);
    formData.append('success_action_status', '200');
    formData.append('x:user-id', ossMetaData.current['x:user-id']);
    formData.append('x:title', audioTitle);
    formData.append('x:audio-uuid', ossMetaData.current['x:audio-uuid']);
    formData.append('x:duration', audioDuration.current+'');
    formData.append('file', audioFile);

    setAudioUploading(true);
    axios
      .post(ossMetaData.current?.host, formData)
      .then(response => {
        message.success(t("音频已上传！"));
        setAudioUploading(false);
        setAudioFile(null);
        setAudioTitle(null);
        audioExtensionRef.current = '';
        audioDuration.current = 0;
      })
      .catch(error => {
        if (!error.response) {
          message.error("Network error. Please check your connection.");
        } else {
          message.error(error.response.data['error']);
        }
        setAudioUploading(false);
        setAudioFile(null);
        setAudioTitle(null);
        audioExtensionRef.current = '';
        audioDuration.current = 0;
      });
  }

  return (
    <div className="file-uploader">
      <p>{t("上传自定义音频/视频文件 (.mp4, .avi, .mp3, .acc) 进行听写")}</p>
      <p>{fileUploadMsg}</p>
      <input type="file" id="fileInput" onChange={(e) => {beforeUpload(e.target.files[0])}} />
      <div>
        {audioTitle &&
        <Typography.Text>{t("文件名：")}</Typography.Text>}
        {audioTitle &&
        <Typography.Text editable={{ onChange: setAudioTitle, }}>{audioTitle}</Typography.Text>}
        {audioExtensionRef.current &&
        <Typography.Text>{audioExtensionRef.current}</Typography.Text>}
      </div>
      <Button type="primary" loading={audioUploading} onClick={uploadAudio}>{t("上传音频")}</Button>
    </div>
  );
}

export default FileUploader;