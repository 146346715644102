import React, { useContext } from 'react';
import { Result, Button } from "antd";
import API from '../api/api.js';
import { AuthContext } from '../context/AuthContextProvider.jsx';

class ErrorBoundary extends React.Component {

  static contextType = AuthContext;

  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    const errorData = {
      message: error.message || error.toString(),
      stack: errorInfo.componentStack || '',
      url: window.location.href,
      userAgent: navigator.userAgent,
      timestamp: Date.now(),
      username: this.context.authHolder.user.username || 'unknown', 
    };
    API
      .post('/web-error', errorData)
      .catch(err => {
        console.error('Error logging failed:', err);
      });
  }

  render() {
    if (this.state.hasError) {
      return (
        <Result
          status="warning"
          title="Oops, something went wrong..."
          extra={
            <Button type="primary" onClick={() => {window.location.href = '/'}}>
              Home Page
            </Button>
          }
        />
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;