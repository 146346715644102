import React, { useState, useEffect } from 'react';
import { message, Space, Card, Row, Col, Typography, Pagination, Flex, Spin } from 'antd';
import { useTranslation } from 'react-i18next';

import API from '../../api/api.js';
import AlphabeticLogo from '../logo/AlphabeticLogo.jsx';
import FileUploader from './FileUploader.jsx';
import { lengthToHHMMSS, formatTimeAgo } from '../../util/FormatUtil.jsx';

import './TranscriptionHistory.css'

const TranscriptionHistoryTab = ({ activeTab }) => {

  const { t, i18n } = useTranslation();

  /**
   * 获取 Task List 相关 state 与 handler
   * {
   *  "page": 1,
   *  "page-size": 10,
   *  "tasks-num": 17,
      "tasks": [
          {
            "taskId": "172989613323496367125",
            "userId": 10000002,
            "audioUuid": "877f8dcc-5eef-48fb-959a-c4c67659f3c2",
            "status": "2",
            "tokenCost": 5,
            "createdTime": "2024-10-26T06:42:13",
            "finishedTime": "2024-10-26T06:42:46",
            "title": "TPO_060_S003_Lecture",
            "audioFileUrl": "audio/877f8dcc-5eef-48fb-959a-c4c67659f3c2.mp3",
            "mimeType": "audio/mpeg",
            "size": 1278609,
            "length": 79,
            "transcriptFileUrl": "",
            "totalSegNum": 0,
            "transcribeTimeSpent": 0,
            "coverImg": ""
          }
        ]
      }
   */
  const TASK_PAGE_SIZE = 7;
  const [taskList, setTaskList] = useState([]);
  const [isTaskListLoading, setIsTaskListLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalTaskNum, setTotalTaskNum] = useState(0);

  const getTaskList = (page, pageSize) => {
    setIsTaskListLoading(true);
    API
      .get("/task/", {
        params: {
          'page': page,
          'page-size': pageSize,
        }
      })
      .then((response) => {
        setTaskList(response.data['tasks']);
        setTotalTaskNum(response.data['tasks-num']);
        setIsTaskListLoading(false);
      })
      .catch((error) => {
        if (!error.response) {
          message.error("Network error. Please check your connection.");
        } else {
          message.error(error.response.data['error']);
        }
        setIsTaskListLoading(false);
      })
  }

  useEffect(() => {
    if (activeTab === 'transcription-history-tab') {
      getTaskList(currentPage, TASK_PAGE_SIZE);
    }
  }, [activeTab])

  useEffect(() => {
    getTaskList(currentPage, TASK_PAGE_SIZE);
  }, [currentPage]);

  const translateTaskStatusColor = (s) => {
    if (s === '0') {
      return 'orange'; // wait
    } else if (s === '1') {
      return 'green'; // transcribing
    } else if (s === '2') {
      return 'green'; // finished
    } else if (s === '3') {
      return 'orange'; // cancelled
    } else if (s === '4') {
      return 'red'; // red
    } else {
      return null;
    }
  }

  const buildTaskStatus = (taskObject) => {
    if (taskObject.status === ' 0') {
      return t("等待中...");
    } else if ( taskObject.status === '1' ) {
      return t("正在转录...");
    } else if ( taskObject.status === '2' ) {
      return t("转录成功") + formatTimeAgo(taskObject.createdTime);
    } else if ( taskObject.status === '3' ) {
      return t("转录取消") + formatTimeAgo(taskObject.createdTime);
    } else if ( taskObject.status === '4' ) {
      return t("转录失败") + formatTimeAgo(taskObject.createdTime);
    } else {
      return null;
    }
  }

  return (
    <div>
      <Space
        direction="vertical"
        size="small"
        style={{
          display: 'flex',
        }}
      >
        {isTaskListLoading
        ? <Flex justify="center" align="center">
            <Spin />
          </Flex>
        : <div>
            {taskList.map((taskObject, index) => (
              <div className='transcription-task-card' key={'transcription-task-card-'+taskObject.taskId}>
                <AlphabeticLogo title={taskObject.title} width={36} height={36}/>
                <div className='transcription-task-text-info'>
                  <div className='transcription-task-text-info__title'>{taskObject.title}</div>
                  <div className='transcription-task-text-info__detail'>
                    <div>
                      <span
                        style={{
                          backgroundColor: translateTaskStatusColor(taskObject.status),
                          width: '6px',
                          height: '6px',
                          borderRadius: '50%',
                          display: 'inline-block',
                        }}
                      />
                      <span>{buildTaskStatus(taskObject)}</span>
                    </div>
                    <div>
                      <span>{lengthToHHMMSS(taskObject.length)}</span>
                      <span>{t("使用")}</span>
                      <span style={{color: 'orange'}}>{taskObject.tokenCost}</span>
                      <span>{t("点券")}</span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        }
        <Pagination 
          align='center' 
          defaultCurrent={currentPage} 
          pageSize={TASK_PAGE_SIZE} 
          total={totalTaskNum} 
          onChange={(page) => {
            setCurrentPage(page);
          }}
        />
        <FileUploader />
      </Space>
    </div>);
}

export default TranscriptionHistoryTab;