import React from 'react';
import { ConfigProvider } from 'antd';

const AntdConfigProvider = ({ children }) => {

  /**
   * 紫色主题色色阶: 
   * #8350f2
   * 米黄色背景:
   * #FFF7D1
   */
  const theme = {
    token: {
      colorPrimary: "#8350f2",
      colorInfo: "#8350f2",
      colorLink: "#8350f2",
    },
  }

  return (
    <ConfigProvider theme={theme}>
      {children}
    </ConfigProvider>
  );
};

export default AntdConfigProvider;