import React, { useContext } from 'react';
import { Button, message } from "antd";
import { useTranslation } from 'react-i18next';

import API from "../../api/api.js";
import { AuthContext } from '../../context/AuthContextProvider.jsx';
import { DictationSessionContext } from '../../context/DictationSessionContextProvider.jsx';


const SubmitDictationButton = ({ dictationText, setIsDictationAnswerDisplayed }) => {

  const { authHolder, setAuthHolder } = useContext(AuthContext);
  const { dictationSession, currentDictationIndex } = useContext(DictationSessionContext);
  const { t, i18n } = useTranslation();

  const handleSubmit = () => {

    // 游客提交一次听写
    if (!authHolder.isLoggedIn) {
      setIsDictationAnswerDisplayed(true);
      message.success("Dictation submitted!");
      return;
    }

    const form = new FormData();
    form.append('session-id', dictationSession['session-id']);
    form.append('audio-uuid', dictationSession['audio-uuid']);
    form.append('sentence-id', dictationSession['sentences'][currentDictationIndex]['sentenceId']);
    form.append('text', dictationText);

    API.post(
      '/dictation-attempt',
      form
    ).then((response) => {
      message.success("Dictation submitted!");
      setIsDictationAnswerDisplayed(true);
    }).catch((error) => {
      if (!error.response) {
        message.error("Network failure");
      } else {
        message.error(error.response.data['error']);
      }
    })
  }

  return (
    <Button onClick={handleSubmit}>{t("提交")}</Button>
  );
}

export default SubmitDictationButton;