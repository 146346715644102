import React, {useContext, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { Avatar, Dropdown, Menu, message } from 'antd';
import { UserOutlined, LoginOutlined, LogoutOutlined, UserAddOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../context/AuthContextProvider.jsx';
import API from '../../api/api.js';
import UserProfileModal from '../modal/UserProfileModal.jsx';

import './AvatarDropdown.css';

const AvatarDropdown = () => {

  const { authHolder, setAuthHolder } = useContext(AuthContext);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  /**
   * 下拉选单：登陆状态
   */
  const itemsLoggedIn = [
    {
      key: 'avatar-dropdown-user-info',
      label: (
        <div className="user-info">
          <div className="user-info-name__active">{authHolder.user.nickname}</div>
          <div className="user-info-balance">
            <span className="user-info-balance__title">{t("账户余额：")}</span>
            <span className="user-info-balance__value">{authHolder.user.balance}</span>
          </div>
        </div>
      ),
    },
    {
      type: 'divider',
    },
    {
      key: 'avatar-dropdown-profile',
      icon: <UserOutlined />,
      onClick: onProfileClick,
      label: t("用户信息"),
    },
    {
      key: 'avatar-dropdown-log-out',
      icon: <LogoutOutlined />,
      onClick: onLogOutClick,
      label: t("退出登录")
    }
  ];

  /**
   * 下拉选单：游客状态
   */
  const itemsNotLoggedIn = [
    {
      key: 'avatar-dropdown-guest-info',
      label: (
        <div className="user-info">
          <div className="user-info-name__guest">{authHolder.user.nickname}</div>
        </div>
      ),
    },
    {
      type: 'divider',
    },
    {
      key: 'avatar-dropdown-log-in',
      icon: <LoginOutlined />,
      onClick: onLogInClick,
      label: t("登录"),
    },
    {
      key: 'avatar-dropdown-sign-in',
      icon: <UserAddOutlined />,
      onClick: onSignUpClick,
      label: t("注册"),
    }
  ];

  /**
   * 登录与注册重定向
   */
  function onLogInClick() {
    navigate('/log-in')
  }

  function onSignUpClick() {
    navigate('/register')
  }

  /**
   * 用户信息详情页 Modal
   */
  const [profileModalIsOpen, setProfileModalIsOpen] = useState(false);

  function onProfileClick() {
    setProfileModalIsOpen(true);
    API
      .get("/balance")
      .then((response) => {
        const newUser = {
          ...authHolder.user,
          balance: response.data['token']
        }
        setAuthHolder((prev) => ({
          ...prev,
          user: newUser,
        }));
      })
  }

  /**
   * 用户登出 
   */
  function onLogOutClick() {
    API
      .get("/log-out")
      .then((response) => {
        setAuthHolder((prev) => ({
          ...prev,
          isLoggedIn: false,
          user: {
            nickname: t("游客"),
          }
        }));
        navigate('/');
        message.success(t("已退出登录！"));
      })
      .catch((error) => {
        message.error("Cannot log out now.");
      })
  }

  return (
    <div>
      <Dropdown 
        menu={
          authHolder.isLoggedIn
          ? { "items": itemsLoggedIn }
          : { "items": itemsNotLoggedIn }
        } 
        trigger={['hover']}
      >
        <Avatar 
          size="default" 
          icon={
            (authHolder.isLoggedIn && authHolder.user.profileImg)
            ? <img src={authHolder.user.profileImg}/>
            : <UserOutlined />
          } 
        />
      </Dropdown>
      <UserProfileModal 
        isOpen={profileModalIsOpen} 
        setIsOpen={setProfileModalIsOpen}
      />
    </div>
  );
}

export default AvatarDropdown;