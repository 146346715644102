import React, { useEffect, useState } from 'react';
import Pinyin from 'tiny-pinyin';

const AlphabeticLogo = ({ title, width=50, height=50 }) => {

  const [imgSrc, setImgSrc] = useState('');

  useEffect(() => {
    const canvas = document.createElement('canvas');
    canvas.width = 2 * width;
    canvas.height = 2 * height;
    const ctx = canvas.getContext('2d');
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';

    // 用渐变填充背景
    const gradient = ctx.createLinearGradient(0, 0, 0, 2 * height);
    gradient.addColorStop(0, '#8350f2');
    gradient.addColorStop(1, '#a882fa');
    ctx.fillStyle = gradient;
    ctx.fillRect(0, 0, 2 * width, 2 * height);

    // 写入白色英文首字母
    ctx.fillStyle = '#FFFFFF';
    ctx.font = 'bold '+height+'px Arial';
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    ctx.fillText(getFirstLetter(title), width, height);

    const dataURL = canvas.toDataURL();
    setImgSrc(dataURL);
  }, []);

  return (
    <img src={imgSrc} alt="Dynamic Logo" width={width} height={height}/>
  );
}

export default AlphabeticLogo;

function getFirstLetter(title) {
  for (let i = 0; i < title.length; i++) {
    const char = title[i];
    if (/[a-zA-Z]/.test(char)) {
      // 英文字符
      return char.toUpperCase();
    }
    else if (/[\u4e00-\u9fa5]/.test(char)) {
      // 中文字符的拼音首字母
      const pinyin = Pinyin.convertToPinyin(char, '', true);
      if (pinyin && pinyin.length > 0) {
        return pinyin[0].toUpperCase();
      }
    }
    else {
      // 如果是数字或其他字符，继续循环
      continue;
    }
  }
  // 如果未找到字母，返回默认值
  return 'Z';
}
