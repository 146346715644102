import React from "react";
import { useNavigate } from "react-router-dom";
import { Result, Button } from "antd";
import { useTranslation } from "react-i18next";

const InvalidUrlPage = () => {

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  return (
    <Result
      status="warning"
      title={t("访问出错了...")}
      extra={
        <Button type="primary" onClick={() => {navigate('/')}}>
          {t("回到主页")}
        </Button>
      }
    />
  )
}

export default InvalidUrlPage;