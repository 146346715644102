import React from "react";
import "./AudioInfoPanel.css";

const ProgressBar = ({ completed, total, setCompleted }) => {

  const percentage = Math.min((completed / total) * 100, 100);

  const handleClick = (event) => {
    if (setCompleted) {
      const { clientX } = event;
      const { left, width } = event.currentTarget.getBoundingClientRect();
      const clickX = clientX - left;
      const clickPercentage = clickX / width;
      const newCompleted = Math.ceil(clickPercentage * total);
      setCompleted(Math.min(Math.max(newCompleted, 1), total));
    }
  };

  return (
    <div className="progress-bar">
      <div className="progress-bar__background" onClick={handleClick}>
        <div
          className="progress-bar__completed"
          style={{ width: `${percentage}%` }}
        >
          <div className="progress-bar__highlight"></div>
        </div>
      </div>
    </div>
  );
}

export default ProgressBar;