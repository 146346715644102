import React, { useEffect, useRef, useContext } from "react";
import { AudioContextContext } from '../../context/AudioContextContextProvider.jsx';

const AudioVisualizer = ({ width=300, height=150 }) => {

  const { isPlaying } = useContext(AudioContextContext);
  const canvasRef = useRef(null);
  const requestRef = useRef();
  const aRef = useRef(0);
  const tRef = useRef(0);
  const decayStartTimeRef = useRef(null);

  function drawCapsule (ctx, x, y, width, height) {
    const radius = width / 2;
    ctx.fillRect(x - radius, y - height / 2, width, height);
    ctx.beginPath();
    ctx.arc(x, y + height / 2, radius, 0, 2 * Math.PI);
    ctx.fill();
    ctx.beginPath();
    ctx.arc(x, y - height / 2, radius, 0, 2 * Math.PI);
    ctx.fill();
  }

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    const barCount = 7;
    const barWidth = 8;
    const barSpacing = 8;
    const canvasWidth = canvas.width;
    const canvasHeight = canvas.height;

    // ctx.fillStyle = '#8350f2';
    ctx.fillStyle = 'rgba(192, 192, 192, 1)';

    const relativeHeights = [];
    for (let i = 0; i < barCount; i++) {
      const x = (i + 1) / (barCount + 1);
      const height = Math.sin(x * Math.PI);
      relativeHeights.push(height);
    }

    let lastTime = null;
    const duration = 1000;

    const animate = (time) => {
      if (!lastTime) lastTime = time;
      const deltaTime = time - lastTime;
      lastTime = time;

      tRef.current += deltaTime * 0.005;

      if (isPlaying) {
        aRef.current = 0.1 * (1 + Math.sin(tRef.current));
        decayStartTimeRef.current = null;
      } else {
        if (decayStartTimeRef.current === null) {
          decayStartTimeRef.current = time;
        }
        const elapsed = time - decayStartTimeRef.current;
        if (elapsed >= duration) {
          aRef.current = 0;
        } else {
          const decayFactor = 1 - elapsed / duration;
          aRef.current *= decayFactor;
        }
      }

      ctx.clearRect(0, 0, canvasWidth, canvasHeight);

      for (let i = 0; i < barCount; i++) {
        const barHeight = aRef.current * relativeHeights[i] * canvasHeight;
        const x = (canvasWidth - (barCount * barWidth + (barCount - 1) * barSpacing)) / 2 + i * (barWidth + barSpacing);
        const y = canvasHeight / 2;
        drawCapsule(ctx, x, y, barWidth, barHeight) 
      }

      requestRef.current = requestAnimationFrame(animate);
    };

    requestRef.current = requestAnimationFrame(animate);

    return () => {
      cancelAnimationFrame(requestRef.current);
    };
  }, [isPlaying]);

  return <canvas ref={canvasRef} width={width} height={height}></canvas>;
}

export default AudioVisualizer;