import React from "react";
import { Button } from "antd";
import { useTranslation } from "react-i18next";

const TranslateButton = () => {

  const { t, i18n } = useTranslation();

  const switchLanguage = () => {
    i18n.changeLanguage(
      i18n.language === "zh" ? "en" : "zh"
    );
  }

  return (
    <img 
      src={i18n.language === "zh" ? "/svg/language-en.svg" : "/svg/language-zh.svg"}
      style={{ width: "20px", height: "20px", cursor: "pointer"}}
      onClick={switchLanguage}
    />
  )
}

export default TranslateButton;