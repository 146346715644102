import React from "react";
import i18n from 'i18next';

/**
 * 以秒为单位的音频长度，转换为 HH:MM:SS 格式的时间戳显示
 * @param {*} length 音频长度
 * @returns 时间戳
 */
export const lengthToHHMMSS = (length) => {
  let seconds = Number(length);
  let h = Math.floor(seconds / 3600);
  let m = Math.floor((seconds % 3600) / 60);
  let s = Math.floor(seconds % 60);
  let hh = h.toString().padStart(2, '0');
  let mm = m.toString().padStart(2, '0');
  let ss = s.toString().padStart(2, '0');
  return `${hh}:${mm}:${ss}`;
}

/**
 * 输入东八区时间戳，返回如下格式的时间表达：
 * 如果距离当前时间在 1分钟内：返回 “n seconds ago”
 * 如果距离当前时间在 1小时内：返回 “n minutes ago”
 * 如果距离当前时间在 1天内：返回 “n hours ago”
 * 如果距离当前时间在 7天内：返回 “n days ago”
 * 否则，返回形如 “on Oct 25, 2024” 的结果
 * @param {*} timestamp 东八区时间戳，形如 2024-10-25T07:19:11
 * @returns 
 */
export const formatTimeAgo = (timestamp) => {

  const timestampWithTZ = timestamp + "+08:00"; // 东八区时间
  const eventDate = new Date(timestampWithTZ);
  if (isNaN(eventDate)) {
    return "";
  }

  const now = new Date();
  const diffMs = now - eventDate;
  const diffSeconds = Math.floor(diffMs / 1000);

  if (diffSeconds < 60) {
    return i18n.language === 'zh' ? `${diffSeconds} 秒前` : `${diffSeconds} seconds ago`;
  }

  const diffMinutes = Math.floor(diffSeconds / 60);
  if (diffMinutes < 60) {
    return i18n.language === 'zh' ? `${diffMinutes} 分钟前` : `${diffMinutes} minutes ago`;
  }

  const diffHours = Math.floor(diffMinutes / 60);
  if (diffHours < 24) {
    return i18n.language === 'zh' ? `${diffHours} 小时前` : `${diffHours} hours ago`;
  }

  const diffDays = Math.floor(diffHours / 24);
  if (diffDays < 7) {
    return i18n.language === 'zh' ? `${diffDays} 天前` : `${diffDays} days ago`;
  }

  return i18n.language === 'zh' ?
    eventDate.toLocaleDateString('zh-CN', {
      month: 'short',
      day: 'numeric',
      year: 'numeric'
    })
  : "on " + eventDate.toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric'
    });
}